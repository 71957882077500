
    /* 3.2 wpo-features-section */

    .wpo-features-section,
    .wpo-features-section-s2{
        background: $white;
        margin-top: -150px;
        z-index: 99;
        position: relative;
        z-index: 1;

        @media(max-width:991px){
            padding-bottom: 100px;
         }

         @media(max-width:767px){
            padding: 80px 0;
         }

        &:before{
            position: absolute;
            left: -10%;
            top: -80px;
            width: 120%;
            height: 120%;
            content: "";
            background: $white;
            z-index: -1;
            border-radius: 50%;

            @media(max-width:767px){
                display: none;
             }
        }

        .wpo-features-wrap{
           position: relative;
           top: -150px;

           @media(max-width:991px){
            top: 0px;
           }
        }


      .wpo-features-item{
          width: 270px;
          height: 270px;
          box-shadow: 0px 22px 60px 0px rgba(37, 48, 65, 0.05);
          background: $white;
          border-radius: 50%;
          text-align: center;
          display: flex;
          justify-content: center;
          flex-direction: column;

          @media(max-width:1199px){
            width: 230px;
            height: 230px;
         }
          @media(max-width:991px){
            width: 150px;
            height: 150px;
         }

         @media(max-width:575px){
            margin: 0 auto;
            width: 130px;
            height: 130px;
         }

          .wpo-features-text{
              h4{
                  font-size: 22px;
                  font-weight: 700;
                  margin-top: 40px;

                  @media(max-width:991px){
                    margin-top: 15px;
                    font-size: 15px;
                 }
              }

          }

          .wpo-features-icon{
                img{
                    max-width: 65px;
                    @media(max-width:991px){
                        max-width: 30%;
                    }
                }
            }

          &.active{
              background: $theme-primary-color;
              position: relative;
              top: -75px;
              @media(max-width:991px){
                top: 0px;
               }

              .wpo-features-text{
                h4{
                    color: $white;
                }
            }
          }
        }
        .col{
            position: relative;
            .angle{
                position: absolute;
                right: 0;
                top: 50%;

                @media(max-width:767px){
                    display: none;
                 }
            }
            &:nth-child(2){
                .wpo-features-item{
                    margin: 0 auto;

                    .wpo-features-icon{
                        img{
                            max-width: 80px;
                            @media(max-width:991px){
                                max-width: 40%;
                            }
                        }
                    }
                }
                .angle{
                    position: absolute;
                    left: 100%;
                    top: 50%;
                }

            }
            &:nth-child(3){
                .wpo-features-item{
                    margin-left:auto;

                    @media(max-width:767px){
                        margin: 0 auto;
                     }
                }
            }
        }
    }


    /* 3.3 wpo-work-section */

    .wpo-work-section{

        @media(max-width:991px){
           padding-bottom: 0;
        }

        .wpo-work-wrap{

            .wpo-work-item{
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                padding: 40px 80px;
                background: $dark-gray;
                border-radius: 15px;
                justify-content: center;
                overflow: hidden;

                &:before{
                    position: absolute;
                    left: -30px;
                    top: -30px;
                    width: 150px;
                    height: 150px;
                    content: "";
                    background: rgba(255,255,255,.05);
                    z-index: -1;
                    border-radius: 50%;
                }

                .wpo-work-icon{
                    margin-right: 20px;
                }

                .wpo-work-text{
                    h2{
                        margin-bottom: 0;
                        a{
                            color: $white;
                        }
                    }
                }
            }

            .col{
                &:last-child{
                    .wpo-work-item{
                        background: $theme-primary-color;
                        @media(max-width:767px){
                          margin-top: 30px;
                        }
                    }

                }
            }
        }
    }


    /*--------------------------------------------------------------
    3.4 wpo-service-section
    --------------------------------------------------------------*/

    .wpo-service-section{
        position: relative;
        z-index: 1;
        &:before{
            position: absolute;
            left: -35%;
            top: 10%;
            width: 70%;
            height: 70%;
            content: "";
            background: rgba(0,82,218,.018);
            z-index: -1;
            border-radius: 50%;
        }
        &:after{
            position: absolute;
            right: -30%;
            bottom: 0;
            width: 64%;
            height: 64%;
            content: "";
            background: rgba(0,82,218,.018);
            z-index: -1;
            border-radius: 50%;
        }


        .wpo-service-item{
            padding: 30px;
            background:$section-bg-color;
            border-radius: 20px;
            margin-bottom: 30px;
            .wpo-service-img{
                overflow: hidden;
                border-radius: 20px;
                img{
                    width: 100%;
                    transform: scale(1);
                    transition: all .3s;
                }
            }

            &:hover{
                .wpo-service-img{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
            .wpo-service-text{
                padding: 40px 20px 15px;

                @media(max-width:1400px){
                    padding: 40px 0px 15px;
                }

                h2{
                    font-size: 28px;
                    font-weight: 700;
                    margin-bottom: 20px;
                    a{
                        color: $dark-gray;
                        &:hover{
                            color: $theme-primary-color;
                        }
                    }

                    @media(max-width:1200px){
                        font-size: 20px;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }
                }
                p{
                    margin-bottom: 25px;

                    @media(max-width:1200px){
                        margin-bottom: 15px;
                    }
                }

                a{
                    color: $dark-gray;
                    font-weight: 700;

                    &:hover{
                        color: $theme-primary-color;
                    }
                    i{
                        margin-left: 15px;
                    }
                }
            }
        }

        @media(max-width:767px){
          padding-bottom: 70px;
        }
    }


    /* 3.5 wpo-fun-fact-section */

    .wpo-fun-fact-section {
        background: $theme-primary-color;
        position: relative;
        background-size: cover;
        overflow: hidden;
        z-index: 1;

        @media(max-width:991px){
            padding-bottom: 80px;
        }
        @media(max-width:767px){
            padding-top: 60px;
        }

        @include media-query(767px) {
            padding-bottom: 30px;
        }


        .wpo-funfacts-text{

            @media(max-width:991px){
               margin-bottom: 30px;
            }
            @media(max-width:767px){
               margin-bottom: 0px;
            }
            h3{
                font-size: 45px;
                font-weight: 700;
                color: $white;
                margin-bottom: 40px;

                @media(max-width:767px){
                    font-size: 25px;
                 }
            }

            .customer-review{
                padding: 20px;
                background: rgba(255,255,255,.05);
                border-radius: 20px;
                display: flex;
                align-items: center;
                margin-bottom: 0;
                max-width: 325px;
                justify-content: center;

                h2{
                    font-size: 55px;
                    font-weight: 700;
                    color: #f4b81c;
                    margin-bottom: 0;
                }
                .reviews{
                    margin-left: 20px;
                    ul{
                        list-style: none;
                        display: flex;
                        margin-bottom: 10px;
                        li{
                            color: #f4b81c;
                            margin-right: 2px;
                        }

                    }
                    span{
                        color: $white;
                    }
                }

            }
        }

        .wpo-fun-fact-grids{
            position: relative;
            @include media-query(767px) {
                display: flex;
                flex-wrap: wrap;
            }

            &:before{
                position: absolute;
                left: 0;
                top: -44%;
                width: 100%;
                height: 188%;
                content: "";
                background: rgba(255,255,255,.05);
                border-radius: 50%;

                @media(max-width:1400px){
                    left: -5%;
                    width: 115%;
                }
                @media(max-width:1200px){
                    left: -15%;
                    width: 130%;
                }
                @media(max-width:991px){
                    display: none;
                }
            }
        }

        .wpo-fun-fact-grids .grid {
            width: 50%;
            float: left;

            @include media-query(575px) {
                width: 100%;
                margin-bottom: 30px;
            }
        }

        .grid {
            display: flex;
            align-items: center;
            margin: 40px 0;
            justify-content: center;

            @media(max-width:991px){
                justify-content: flex-start;
            }

            .icon{
                margin-right: 20px;
            }

            h3 {
                font-size: 45px;
                font-size: calc-rem-value(45);
                font-weight: 700;
                color: $white;
                margin: 0 0 0px;
                font-family: $heading-font;

                @include media-query(1200px) {
                    font-size: 50px;
                    ont-size: calc-rem-value(50);
                }

                @include media-query(991px) {
                    font-size: 40px;
                    font-size: calc-rem-value(40);
                }

                @include media-query(767px) {
                    font-size: 35px;
                    font-size: calc-rem-value(35);
                }
            }
        }



        .grid h3 + p {
            font-size: 16px;
            font-size: calc-rem-value(16);
            margin: 0;
            color: $white;

            @include media-query(991px) {
                font-size: 14px;
                font-size: calc-rem-value(14);
            }
        }
    }


    /*--------------------------------------------------------------
    wpo-projects
    --------------------------------------------------------------*/
    .wpo-projects {

        padding-top: 100px;

        @include media-query(767px) {
            padding-top: 80px;
        }

        .projects-grids{
            margin: 0 -7.5px;
        }
        .projects-grids .grid {
            width: 33.33%;
            float: left;
            padding: 0 7.5px 15px;

            @include media-query(1199px) {
                width: 50%;
            }

            @include media-query(600px) {
                width: 100%;
            }
        }

        .grid{
            .project-inner{
                position: relative;
                .hover-content  {
                    background: transparentize($theme-primary-color, 0.1);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    -webkit-transform: scale(0.7,0.7);
                    transform: scale(0.7,0.7);
                    @include transition-time(0.5s);
                }

                .img-holder{
                    img{
                        width: 100%;
                    }
                }
            }
        }

        .grid:hover .hover-content {
            opacity: 1;
            top: 0;
            -webkit-transform: scale(1,1);
            transform: scale(1,1);

            &:before {
                top: 0;
            }
        }

        .grid .details {
            width: 100%;
            padding: 0 35px 45px;
            position: absolute;
            left: 0;
            bottom: 0;

            @media(max-width:767px){
                padding: 0 20px 30px;
            }
        }

        .grid .details h3 {
            font-size: 25px;
            font-size: calc-rem-value(25);
            font-weight: 600;
            line-height: 1.3em;
            margin: 0 0 0.20em;
            text-transform: capitalize;

            @media(max-width:767px){
                font-size: 16px;
                font-size: calc-rem-value(16);
            }

            a {
                color: $white;
            }

            a:hover {
                color:$white;
            }
        }

        .grid .details p {
            font-family: $heading-font;
            font-size: 16px;
            font-size: calc-rem-value(16);
            font-weight: 500;
            color: $white;
            margin: 0;
            text-transform: capitalize;

            @media(max-width:767px){
                font-size: 14px;
                font-size: calc-rem-value(14);
            }
        }

        .See-all-btn{
           text-align: center;
           margin-top: 30px;
           cursor: pointer;
        }
    }



    /*--------------------------------------------------------------
    3.6 wpo-team-section
    --------------------------------------------------------------*/

    .wpo-team-section{
        position: relative;
        z-index: 1;
        padding-bottom: 100px;
        background: $section-bg-color;

        @media(max-width:991px){
           padding-bottom: 80px;
        }
        @media(max-width:767px){
           padding-bottom: 70px;
        }

        .wpo-team-wrap{
            .wpo-team-item{
                margin-bottom: 30px;
                background: $white;
                border-radius: 50px;
                .wpo-team-img{
                    position: relative;
                    &:before{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        content: "";
                        background: $dark-gray;
                        opacity: 0;
                        transition: all .3s;
                        border-radius: 50px;
                    }
                    img{
                        width: 310px;
                        border-radius: 50px;
                    }

                    ul{
                        list-style: none;
                        display: flex;
                        justify-content: center;
                        margin-top: 10px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);

                        li{
                            margin:0 6px;
                            transition: all .3s;
                            margin-top: -40px;
                            opacity: 0;
                            visibility: hidden;

                            &:nth-child(2){
                                transition: all .4s;
                                margin-top: -70px;
                            }
                            &:nth-child(3){
                                transition: all .5s;
                                margin-top: -100px;
                            }

                            a{
                                display: block;
                                width: 40px;
                                height: 40px;
                                line-height: 40px;
                                background: $white;
                                border-radius: 50%;
                                color: $dark-gray;
                                text-align: center;

                                &:hover{
                                    background: $theme-primary-color;
                                    color: $white;
                                }
                            }
                        }
                    }
                }

                .wpo-team-text{
                    padding: 35px;

                    @media(max-width:1400px){
                        padding:35px 20px;
                    }

                    span{
                        font-size: 14px;
                        text-transform: capitalize;
                        color: #666666;
                        transition: all .3s;
                    }

                    h2{
                        font-size: 25px;
                        font-weight: 700;
                        margin-bottom: 10px;
                        transition: all .3s;
                        @media(max-width:1400px){
                            font-size: 20px;
                        }
                        @media(max-width:1200px){
                            font-size: 19px;
                        }
                        a{
                            color: $dark-gray;

                            &:hover{
                                color: $theme-primary-color;
                            }
                        }
                    }

                }

                &:hover{
                    .wpo-team-img{
                        &:before{
                            opacity: .5;
                        }
                        ul{
                            li{
                                margin-top: 0;
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
    3.7 wpo-contact-section
    --------------------------------------------------------------*/

    .wpo-contact-section,
    .wpo-contact-section-s2{
        position: relative;
        background-size: cover;
        z-index: 1;
        background: $dark-gray;
        overflow: hidden;

        @media(max-width:991px){
            padding-top: 0;
          }

        .wpo-contact-img{
            position: absolute;
            right:0px;
            top: 0;
            width: 34%;
            height: 100%;

            @media(max-width:991px){
              position: relative;
              width: 100%;
              height: 600px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .video-btn button.wrap{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 180px;
                height: 180px;
                border: 2px solid $white;
                text-align: center;
                line-height: 190px;
                border-radius: 50%;
                background: transparent;

                @media(max-width:767px){
                    width: 100px;
                    height: 100px;
                    line-height: 115px;
                  }

                .fi{
                    &::before{
                        font-size: 30px;
                        color: $white;
                    }
                }
            }
        }

        .wpo-contact-img-s2{
            position: absolute;
            left: -5%;
            bottom: -5%;
            z-index: -1;

            @media(max-width:991px){
                display: none;
            }

            img{
                max-width: 75%;
            }
        }

        .wpo-contact-form-area{

            padding-right: 80px;

            @media(max-width:991px){
              padding-right: 0;
              padding-top: 120px;
            }
            @media(max-width:767px){
              padding-top: 80px;
            }

            .wpo-section-title-s2{

                h2{
                    margin-bottom: 20px;
                    color: $white;

                    @media(max-width:767px){
                        margin-bottom: 20px;
                     }
                }

            }
            .col{
                padding: 0 7px;
            }

            .form-group{
                margin-bottom: 30px;
                label{
                    margin-bottom: 15px;
                    color: $white;
                }

                .errorMessage{
                    color: red;
                }
            }

            .form-control{
                width: 100%;
                height: 55px;
                margin-bottom: 15px;
                border: 0;
                border: none;
                background: #384559;
                color: #bababa;

                &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }

            textarea{
                padding: 20px;
                &.form-control{
                    height: 165px;
                }
            }
            .form-control{
                &::-webkit-input-placeholder { /* Edge */
                    color: #bababa;
                  }

                  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #bababa;
                  }

                  &::placeholder {
                    color: #bababa;
                  }
            }

            select.form-control{
                color: #bababa;
                -webkit-appearance: none;
                -ms-appearance: none;
                -o-appearance: none;
                appearance: none;
                -moz-appearance: none;
                background: #384559 url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
                position: relative;

                option{
                    background: $white;
                    color: $dark-gray;
                }
            }


            .contact-validation-active label.error {
                margin-top: -8px;
                margin-bottom: 20px;
            }

        }
    }

    .wpo-contact-section-s2{
        background: $white;

        @media(max-width:991px){
          padding-top: 100px;
        }

        .wpo-contact-form-area{
            padding: 70px;
            background: $dark-gray;

            @media(max-width:991px){
                padding: 40px;
              }

        }

        .wpo-section-title-s2{
            text-align: center;
            h2 {
                font-size: 35px;
                line-height: 40px;
                margin-top: 10px;

                @media(max-width:575px){
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }

        .submit-area{
            text-align: center;
        }
    }


    /* 3.8 wpo-testimonials-section */


    .wpo-testimonials-section{

        .testimonials-left{
            padding-right: 80px;

            @media(max-width:1200px){
                margin-bottom: 50px;
                padding-right: 0;
            }

            span{
                font-size: 20px;
                font-weight: 500;
                color: #004585;
                position: relative;
                margin-top: -0.31em;
                display: inline-block;
                text-transform: uppercase;
                font-family: $heading-font;
                margin-bottom: 10px;

                &:before{
                    content: "";
                    background: $theme-primary-color;
                    width: 30px;
                    height: 2px;
                    position: absolute;
                    left: -35px;
                    top: 12px;
                }
            }

            h3{
                font-size: 40px;
                font-weight: 600;
                margin-bottom: 20px;

                @media(max-width:1300px){
                    font-size: 30px;
                }
                @media(max-width:1200px){
                    font-size: 40px;
                }
                @media(max-width:575px){
                    font-size: 30px;
                }
            }
            p{
                margin-bottom: 25px;
            }
        }

        .testimonials-wrapper{

            .slick-slide{
                padding: 0 10px;
            }
            .testimonials-item{
                .testimonials-item-top{
                    padding:40px;
                    background: $section-bg-color;
                    position: relative;
                    z-index: 1;

                    &:before{
                        content: '';
                        position: absolute;
                        bottom: -25px;
                        left: 40px;
                        margin-top: 0;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 25px 25px 0 25px;
                        border-color: $section-bg-color transparent transparent transparent;
                    }

                    &:after{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        content: "\e726";
                        font-family: "themify";
                        font-size: 150px;
                        color: #e7f0fe;
                        transform: translate(-50%, -50%);
                        z-index: -1;
                        opacity: .7;
                    }

                    p{
                        margin-bottom: 0;
                        line-height: 30px;
                    }
                }

                .testimonials-item-bottom{
                    padding-top: 30px;
                    padding-left: 35px;
                    display: flex;
                    align-items: center;

                    .testimonials-item-bottom-author{
                        margin-right: 15px;
                        img{
                            width: 70px;
                            height: 70px;
                            border-radius: 50%;
                        }
                    }

                    .testimonials-item-bottom-author-text{
                        h3{
                            font-size: 22px;
                            font-weight: 600;

                            @media(max-width:575px){
                              font-size: 20px;
                            }
                        }
                        span{
                            font-size: 16px;
                            color: #6c6c6c;
                        }
                    }
                }
            }

            .owl-nav {
                display: none;
            }
        }
    }



    /* 3.9 wpo-blog-section */

    .wpo-blog-section{
        padding-bottom: 90px;
        background:$section-bg-color;

        @media(max-width:767px){
            padding-bottom: 60px;
            padding-top: 60px;
        }
        .wpo-blog-item{
            margin-bottom: 30px;
            background: $white;

            .wpo-blog-img{
                img{
                    width: 100%;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
            }


            .wpo-blog-content {
                box-shadow: 0px 2px 20px 0px rgba(62, 65, 159, 0.09);
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                padding:40px 30px;

                @media(max-width:1200px){
                    padding:30px 10px;
                }

                .date{
                    color: $theme-primary-color;
                }


                h2{
                    font-size: 22px;
                    font-family: $heading-font;
                    margin-bottom: 20px;
                    line-height: 36px;
                    font-weight: 700;
                    margin-top: 20px;

                    @media(max-width:1200px){
                        font-size: 21px;
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }

                    a{
                        color: $heading-color;

                        &:hover{
                            color: $theme-primary-color;
                        }
                    }
                }

                ul{
                    list-style: none;
                    display: flex;

                    li{
                        position: relative;

                        a{
                            color: $theme-primary-color;
                        }

                        &+li{
                            padding-left: 10px;
                            margin-left: 10px;

                            &:before{
                                position: absolute;
                                left: 0;
                                top: 50%;
                                width: 1px;
                                height: 15px;
                                content: "";
                                background: #666;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }

            }
        }
    }
