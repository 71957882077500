/* 6. wpo-about-section */

.wpo-about-section{
    background: $section-bg-color;
    .wpo-about-img{
        img{
            width: 504px;
            height: 650px;
        }
    }

    .wpo-section-title-s2{
        margin-bottom: 30px;
    }
    .wpo-about-content{
       padding-left: 50px;

       @media(max-width:991px){
            padding-left: 0;
            margin-top: 50px;
       }

       
       .signeture{
        margin-top: 40px;

        h4{
            font-size: 22px;
            font-family: $base-font;
            font-weight: 700;
            color: #5c6c8c;
        }

        p{
            font-size: 16px;
            color: #687693;
            margin-bottom: 30px;
        }
    }
    }
}
